/*
    Created by Rogachev Sergey on 08.02.2016.
*/

(function() {
    "use strict";

    var app = angular.module("touchSpin", []);

    app.directive("touchspin", function($timeout) {
        return {
            restrict: "AE",
            require: "ngModel",
            scope: {
                config: "="
            },
            link: function($scope, $element, attrs, $ctrl) {

                $ctrl.$parsers.push(function(val) {

                    if (angular.isUndefined(val)) {
                        val = "";
                    }
                    return parseFloat((String(val)).replace($scope.config.delimiter, "."));
                });

                $ctrl.$formatters.push(function(val) {
                    if (!val)
                        return val;

                    return (String(val)).replace(".", $scope.config.delimiter);
                });

                (function init() {
                    $element.TouchSpin($scope.config);
                })();
            }
        };
    });
})();
