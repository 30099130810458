import $ from "jquery";
import "node_modules/jquery-mask-plugin";
/**
 * @description jquery-mask-plugin monkey patch
 * */
$.fn.masked = function(val) {
    const instance = this.data('mask');
    if (instance) {
        return instance.getMaskedVal(val);
    }
    return val;
};
